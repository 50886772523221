export const allColors = {
  green: '#005E57',
  terracotta: '#E05314',
  midGreen: '#00A877',
  brightGreen: '#98C000',
  brightOrange: '#FC9000',
  turquoiseGreen: '#009FA0',
  warmYellow: '#FFB807',
  backgroundLightGrey: '#F0F0F0',
  backgroundDarkGrey: '#6E6C70',
  backgroundMidGrey: '#E7E7E7',
  textDark: '#313230',
  textDarker: '#000000',
  textMedium: '#4B494C',
  textLight: '#9E9E9E',
  linkColorCorporate: '#009FA0',
  linkColorIndustry: '#FC9000',
  black: '#000000',
  lineDark: '#C6C6C6',
  lineLight: '#E5E5E5',
  red: '#AD271D',
  darkTerracotta: '#C73612',
  lightTerracotta: '#FEECE8',
  white: '#ffffff',
  orange: '#EB2814',
  green3: '#00E2B2',
};

export const chartColors = [
  // allColors.midGreen,
  // allColors.terracotta,
  // allColors.turquoiseGreen,
  // allColors.warmYellow,
  // allColors.red,
  // allColors.brightOrange,
  // '#202E78',
  '#7C4DFF',
  '#4DD0E1',
  '#FFB807',
  '#7AC6E8',
  '#F06292',
  '#FFAB91',
];

// Default Colors
export const colors = {
  primaryColor: allColors.orange,
};

// Typography
export const typography = {
  h1FontSizeDesktop: '5rem',
  h1FontSizeTablet: '3.75rem',
  h1FontSizeMobile: '2.875rem',
  h2FontSizeDesktop: '2.25rem',
  h2FontSizeTablet: '2rem',
  h2FontSizeMobile: '1.75rem',
  h3FontSizeDesktop: '1.875rem',
  h3FontSizeTablet: '1.75rem',
  h3FontSizeMobile: '1.625rem',
  h4FontSizeDesktop: '1.5rem',
  h4FontSizeTablet: '1.25rem',
  h4FontSizeMobile: '1.125rem',
  subHeadingSizeDesktop: '1.375rem',
  subHeadingSizeTablet: '1.25rem',
  subHeadingSizeMobile: '1.125rem',
  ctaDesktop: '1rem',
  ctaTablet: '1rem',
  ctaMobile: '1rem',
  fontColorPrimary: colors.textDark,
  fontFamilySansSerif: `"Arial", "myriad-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
};
