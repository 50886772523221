import React, { Suspense, useRef } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StoreProvider, createStore } from 'easy-peasy';
import { ToastContainer } from 'react-toastify';
// hack to fix overriding jss
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import {
  createGenerateClassName,
  jssPreset,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import LogRocket from 'logrocket';

import PrivateRoute from './containers/PrivateRoute';
import NavBar from './components/NavBar';
import theme from './constants/themes';
import model from './model';
import client from './containers/Apollo';
import routes from './routes';
import Loading from './components/Loading';

import 'react-toastify/dist/ReactToastify.css';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('metso/crusher-product-catalog-app', {
    network: {
      requestSanitizer: request => {
        if (request.url.toLowerCase().indexOf('idToken' !== -1)) {
          request.url = null;
        }
        request.headers.Authorization = null;
        request.headers.authorization = null;
        return request;
      },
    },
  });
}

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const PageArea = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  -webkit-overflow-scrolling: touch;
`;

const store = createStore(model);

const App = () => {
  const scrollableDiv = useRef(null);
  return (
    <>
      <ApolloProvider client={client}>
        <JssProvider jss={jss} generateClassName={generateClassName}>
          <StylesProvider injectFirst>
            <ThemeProvider theme={{ ...theme, breakpoints: [] }}>
              <MuiThemeProvider theme={theme}>
                <Router>
                  <>
                    <Route
                      path="*"
                      render={props => (
                        <NavBar
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...props}
                        />
                      )}
                    />
                    <Suspense fallback={<Loading />}>
                      <PageArea ref={scrollableDiv}>
                        <Switch>
                          {routes.map(
                            ({
                              private: isPrivate,
                              path,
                              exact,
                              component,
                              superPrivate,
                            }) =>
                              isPrivate ? (
                                <PrivateRoute
                                  key={path}
                                  path={path}
                                  exact={exact}
                                  component={component}
                                  scrollableDiv={scrollableDiv && scrollableDiv}
                                  superPrivate={superPrivate && superPrivate}
                                />
                              ) : (
                                <Route
                                  key={path}
                                  path={path}
                                  exact={exact}
                                  component={component}
                                />
                              ),
                          )}
                        </Switch>
                      </PageArea>
                    </Suspense>
                  </>
                </Router>
              </MuiThemeProvider>
            </ThemeProvider>
          </StylesProvider>
        </JssProvider>
      </ApolloProvider>
      <ToastContainer position="top-center" />
    </>
  );
};

const Root = () => (
  <StoreProvider store={store}>
    <App />
  </StoreProvider>
);

export default Root;
