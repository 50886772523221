import { lazy } from 'react';

import Login from './containers/Login';
import Callback from './components/Callback';

const Products = lazy(() => import('./containers/Products'));
const Collection = lazy(() => import('./components/Collection'));
const UploadPage = lazy(() => import('./containers/UploadFile'));
const Geometries = lazy(() => import('./containers/Geometries'));
const Comparison = lazy(() => import('./containers/Comparison'));
const Feedback = lazy(() => import('./containers/Feedback'));
const Changelog = lazy(() => import('./containers/Changelog'));

const SingleGeometry = lazy(() => import('./components/SingleGeometry'));
const UploadCoordinates = lazy(() => import('./containers/UploadCoordinates'));
const SingleProductContainer = lazy(() =>
  import('./containers/SingleProductContainer'),
);
const NotFound = lazy(() => import('./components/404Page'));

export default [
  {
    path: '/geometries',
    exact: true,
    component: Geometries,
    private: true,
  },
  {
    path: '/',
    exact: true,
    component: Products,
    private: true,
  },
  {
    path: '/geometries/:id',
    exact: true,
    component: SingleGeometry,
    private: true,
  },

  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/callback',
    component: Callback,
  },
  {
    path: '/import/products',
    exact: true,
    component: UploadPage,
    private: true,
    superPrivate: 'global',
  },
  {
    path: '/import/geometries',
    exact: true,
    component: UploadCoordinates,
    private: true,
    superPrivate: 'global',
  },
  {
    path: '/collection',
    exact: true,
    component: Collection,
    private: true,
  },
  {
    path: '/feedback',
    exact: true,
    component: Feedback,
    private: false,
  },
  {
    path: '/changelog',
    exact: true,
    component: Changelog,
    private: false,
  },
  {
    path: '/comparison',
    component: Comparison,
    exact: true,
    private: true,
  },
  {
    path: '/product/:productId',
    component: SingleProductContainer,
    exact: true,
    private: true,
  },
  {
    path: '*',
    component: NotFound,
  },
];
