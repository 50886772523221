import ApolloClient from 'apollo-client';
import { persistCache } from 'apollo-cache-persist';
import { WebSocketLink } from 'apollo-link-ws';
import { withClientState } from 'apollo-link-state';
import { HttpLink } from 'apollo-link-http';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ApolloLink, Observable, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import localforage from 'localforage';
import { getTokens, setSession, ID_TOKEN, logOut } from '../../utils/auth';

const urlPrefix =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:5000' : '';

const cache = new InMemoryCache({});
const cacheStorage = localforage.createInstance({
  name: 'crusherWearStorage',
});
persistCache({
  cache,
  storage: cacheStorage,
  key: 'crusherwearcatalog-app',
  debug: process.env.NODE_ENV !== 'production',
  maxSize: false,
});

// TODO: urls to env and fix client state and persist cache
const httpUrl = `${
  process.env.NODE_ENV !== 'production' ? 'http://' : 'https://'
}${process.env.REACT_APP_HASURA_ENDPOINT_URL}/v1/graphql`;
const wsUrl = `${process.env.NODE_ENV !== 'production' ? 'ws://' : 'wss://'}${
  process.env.REACT_APP_HASURA_ENDPOINT_URL
}/v1/graphql`;

const wsLink = new WebSocketLink(
  new SubscriptionClient(wsUrl, {
    reconnect: true,
    connectionParams: () => {
      const tokens = getTokens();
      const idToken = tokens[`${ID_TOKEN}`] || '';
      return { headers: { Authorization: `Bearer ${idToken}` } };
    },
  }),
);

const httpLink = new HttpLink({
  uri: httpUrl,
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const stateLink = withClientState({
  cache,
  defaults: {
    networkStatus: {
      __typename: 'NetworkStatus',
      isConnected: true,
    },
    newCustomer: [
      {
        __typename: 'NewCustomer',
        name: '',
        metsoId: '',
        country: '',
        city: '',
        email: '',
        description: '',
      },
    ],
  },
  resolvers: {
    Query: {
      // newCustomer: (_, variables, context) => null,
    },
    Mutation: {
      updateNetworkStatus: (_, { isConnected }, { cache: _cache }) => {
        _cache.writeData({
          data: {
            networkStatus: {
              __typename: 'NetworkStatus',
              isConnected,
            },
          },
        });
        return null;
      },
    },
  },
});

const request = async operation => {
  let { idToken } = getTokens();
  const encodedToken = jwt.decode(idToken);
  // If token has less than half an hour time left lets try to renew it
  if (Math.floor(Date.now() / 1000) + 1800 > encodedToken.exp) {
    try {
      const newToken = await axios.post(`${urlPrefix}/auth/metso/refresh`, {
        idToken,
      });
      const { expiresAt } = (newToken && newToken.data) || {
        expiresAt: idToken.exp,
        idToken: encodedToken,
      };
      idToken = newToken && newToken.data && newToken.data.idToken;
      setSession({ idToken, expiresAt });
    } catch (err) {
      logOut();
    }
  }
  operation.setContext({
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    }),
);

const client = new ApolloClient({
  link: ApolloLink.from([requestLink, stateLink, link]),
  cache,
  defaultOptions: {
    // graphql() hoc uses watchQuery
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;
