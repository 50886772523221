import { createMuiTheme } from '@material-ui/core';
import { lighten, darken } from 'polished';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Arial',
      'myriad-pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    primary: {
      light: lighten(0.1, '#EB2814'),
      main: '#EB2814',
      dark: darken(0.1, '#EB2814'),
      contrastText: '#fff',
    },
    secondary: {
      main: '#E05314',
    },
  },
  overrides: {
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(odd)': {
          backgroundColor: '#f8f8f8',
        },
        cursor: 'pointer',
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',
      },
    },
    MUIDataTableToolbar: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MUIDataTablePagination: {
      root: {
        '&:last-child': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
});

export default theme;
