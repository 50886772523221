/* eslint-disable camelcase */
import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components/macro';
import {
  TextField,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { withRouter } from 'react-router-dom';

import SEARCH_PRODUCTS from './product-search-query';
import { allColors } from '../../constants/styles';

const SearchBarWrapper = styled.div`
  margin-left: auto;
  margin-right: 25px;
  & fieldset {
    border-width: 2px;
  }
`;

const StyledTextField = styled(TextField)`
  color: ${allColors.backgroundLightGrey};
  height: 38px;
  width: 309px;
  flex-direction: row;
  justify-content: flex-end;
  &:active {
    border-color: ${allColors.backgroundLightGrey};
  }
`;

const SearchIcon = styled(Search)`
  transform: rotate(90deg);
  color: ${allColors.backgroundDarkGrey};
`;

const StyledTableRow = styled(TableRow)`
  display: flex;
  flex-direction: row;
  height: 60px;
`;

const TableCell = styled(MuiTableCell)`
  width: 100px;
  flex-shrink: 0;
  flex: 1 0 0;
  display: flex;
  flex-display: row;
  padding-left: 10px;
  padding-right: 10px !important;
  white-space: nowrap;
  align-items: center;
`;

const CellValue = styled.span`
  width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Dropdown = ({
  data: { search_products },
  keyword,
  history,
  handleRowClick,
}) => {
  if (search_products && search_products.length !== 0) {
    return (
      <Paper
        style={{
          position: 'absolute',
          top: '64px',
          left: 0,
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        <Table data-testid="search-dropdown-test">
          <TableHead component="thead">
            <StyledTableRow component="tr">
              <TableCell component="td" variant="head">
                Id
              </TableCell>
              <TableCell component="td" variant="head">
                Item Number
              </TableCell>
              <TableCell component="td" variant="head">
                Family
              </TableCell>
              <TableCell component="td" variant="head">
                Model
              </TableCell>
              <TableCell component="td" variant="head">
                Type
              </TableCell>
              <TableCell component="td" variant="head">
                Cavity
              </TableCell>
              <TableCell component="td" variant="head">
                Sub1
              </TableCell>
              <TableCell component="td" variant="head">
                Sub2
              </TableCell>
              <TableCell component="td" variant="head">
                Sub3
              </TableCell>
              <TableCell component="td" variant="head">
                Sub4
              </TableCell>
              <TableCell component="td" variant="head">
                Sub5
              </TableCell>
              <TableCell component="td" variant="head">
                Additional Info1
              </TableCell>
              <TableCell component="td" variant="head">
                Additional Info 2
              </TableCell>
              <TableCell component="td" variant="head">
                Info 1
              </TableCell>
              <TableCell component="td" variant="head">
                Info 2
              </TableCell>
              <TableCell component="td" variant="head">
                Info 3
              </TableCell>
              <TableCell component="td" variant="head">
                Desc 1
              </TableCell>
              <TableCell component="td" variant="head">
                Desc 2
              </TableCell>
              <TableCell component="td" variant="head">
                Picture
              </TableCell>
              <TableCell component="td" variant="head">
                Material
              </TableCell>
              <TableCell component="td" variant="head">
                Weight
              </TableCell>
              <TableCell component="td" variant="head">
                Catalog
              </TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {search_products.map(row => (
              <StyledTableRow
                key={row.id}
                component="tr"
                onClick={() => handleRowClick(row.id)}
                hover
              >
                <TableCell component="td" variant="body">
                  <CellValue>{row.id}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.itemNumber}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.family}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.model}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.type}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.cavity}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.sub1}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.sub2}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.sub3}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.sub4}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.sub5}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.additionalNfo1}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.additionalNfo2}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.info1}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.info2}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.info3}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.desc1}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.desc2}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.picture}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.material}</CellValue>
                </TableCell>
                <TableCell component="td" variant="body">
                  <CellValue>{row.weight}</CellValue>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
  return (
    <Paper
      style={{
        position: 'absolute',
        top: '64px',
        left: 0,
        width: '100%',
        overflowX: 'hidden',
      }}
    >
      <Table>
        <TableHead>
          <StyledTableRow>
            <TableCell component="td" variant="head">
              Your search - {keyword} - did not match any products.
            </TableCell>
          </StyledTableRow>
        </TableHead>
      </Table>
    </Paper>
  );
};

Dropdown.propTypes = {
  data: shape({}),
  keyword: string,
  history: shape({}).isRequired,
};

Dropdown.defaultProps = {
  data: {},
  keyword: '',
};

const SearchBar = ({ history }) => {
  const [keyword, setKeyword] = useState('');
  const [toggle, setToggle] = useState(true);
  const { data, error } = useQuery(SEARCH_PRODUCTS, {
    variables: { search: keyword },
    skip: keyword.length < 3,
    suspend: false,
  });

  const handleRowClick = rowId => {
    setToggle(false);
    setKeyword('');
    history.push(`/product/${rowId}`);
  };

  const handleOnChange = event => {
    setToggle(true);
    setKeyword(event.target.value);
  };

  if (error) {
    return <p>Error ${error.message}</p>;
  }
  return (
    <SearchBarWrapper>
      <StyledTextField
        placeholder="Search your item here"
        onChange={event => handleOnChange(event)}
        value={keyword}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        data-testid="searchbar-test"
      />
      {data && toggle && (
        <Dropdown
          data={data}
          keyword={keyword}
          history={history}
          handleRowClick={handleRowClick}
        />
      )}
    </SearchBarWrapper>
  );
};

SearchBar.propTypes = {
  history: shape({}).isRequired,
};

export default withRouter(SearchBar);
