import { action } from 'easy-peasy';
import uniqby from 'lodash.uniqby';

const model = {
  page: {
    title: '',
    updateTitle: action((state, payload) => {
      // eslint-disable-next-line
      state.title = payload;
    }),
  },
  collection: {
    collected: JSON.parse(localStorage.getItem('collected')) || [],
    add: action((state, payload) => {
      const uniq = uniqby([...state.collected, ...payload], 'id');
      // eslint-disable-next-line no-param-reassign
      state.collected = uniq;
      localStorage.setItem('collected', JSON.stringify(uniq));
      // return {
      //   ...state,
      //   collected: uniq,
      // };
    }),
    remove: action((state, payload) => {
      if (Array.isArray(payload)) {
        const newData = state.collected.filter(i => !payload.includes(i.id));
        localStorage.setItem('collected', JSON.stringify(newData));
        // eslint-disable-next-line
        state.collected = newData;
      } else {
        const newData = state.collected.filter(i => i.id !== payload);
        localStorage.setItem('collected', JSON.stringify(newData));
        // eslint-disable-next-line
        state.collected = newData;
      }
    }),
  },
  search: {
    isSearchOpen: false,
    toggleSearch: action((state, payload) => {
      // eslint-disable-next-line
      state.isSearchOpen = payload;
    }),
  },
  upload: {
    activeStep: 0,
    fileData: null,
    loading: false,
    updateStep: action((state, payload) => {
      // eslint-disable-next-line
      state.activeStep = payload;
    }),
    setFileData: action((state, payload) => {
      // eslint-disable-next-line
      state.fileData = payload;
    }),
    setLoading: action((state, payload) => {
      // eslint-disable-next-line
      state.loading = payload;
    }),
  },
};

export default model;
