import React from 'react';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import bgImage from '../../assets/login-bg.svg';
import logoUrl from '../../assets/metso_logo_black.svg';

const urlPrefix =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:5000' : '';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position: bottom right;
  background-image: url(${bgImage});
`;

const Title = styled.h1`
  font-size: 2.25rem;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 30px;
`;

const TitleSmall = styled.p`
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 0px;
`;

const Logo = styled.img`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 124px;
`;

const Version = styled.div`
  position: fixed;
  bottom: 10px;
`;

const LoginContainer = () => (
  <Container>
    <Logo alt="Metso Logo" src={logoUrl} />
    <TitleSmall>Welcome to</TitleSmall>
    <Title>Crusher wears product catalog</Title>
    <Button
      id="loginBtn"
      variant="contained"
      color="primary"
      size="large"
      href={`${urlPrefix}/auth/metso`}
    >
      Login with Metso Login
    </Button>
    <Version>{process.env.REACT_APP_VERSION}</Version>
  </Container>
);

export default LoginContainer;
