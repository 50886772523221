import { gql } from 'apollo-boost';

export default gql`
  query searchProducts($search: String!) {
    search_products(limit: 10, args: { search: $search }) {
      id
      itemNumber
      family
      model
      type
      cavity
      sub1
      sub2
      sub3
      sub4
      sub5
      desc1
      desc2
      weight
      material
      additionalNfo1
      additionalNfo2
      info1
      info2
      info3
      picture
    }
  }
`;
